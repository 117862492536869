require('../scss/main.scss');
export {};

const $ = require('jquery');
require('bootstrap');

$(document).ready(function() {
  $('.mobile-nav-trigger').bind('click touchstart', function(event) {
    event.preventDefault();
    $('body').toggleClass('nav-popup-visible');
    $(this).toggleClass('active');

    if($('.nav-primary-mobile .mobile-parent').hasClass('active')) {
      $('.nav-primary-mobile .mobile-parent').removeClass('active');
      $('.nav-primary-mobile .mobile-parent').find('.sub-menu').removeClass('active').slideUp();
    }

    //$('.mobile-nav-wrapper').toggleClass('active');
  });

  function closeMobileNav() {
    $('body').removeClass('nav-popup-visible');
    $('.mobile-nav-trigger').removeClass('active');
    $('.nav-primary-mobile .mobile-parent').removeClass('active');
  }

  if(window.innerWidth > 768) {
    closeMobileNav();
  }

  $(window).resize(function(){
    if(window.innerWidth > 768) {
      setTimeout(function() {
        closeMobileNav();
      }, 100);
    }
  });
});
